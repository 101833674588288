<template>
  <div class="container">
    <div class="is-size-4 center">Samochody zapisane w aplikacji</div>
  </div>
</template>

<script>
export default {
  name: "AdminCars",
};
</script>

<style lang="scss" scoped>
</style>